import React, { useState } from 'react';
import "./LibraryMainPage.css";
import { useDispatch, useSelector } from "react-redux";
import "./LibraryMainPage.css";
import { useNavigate } from 'react-router-dom';
import NavbarTabs from '../NavbarTabs/NavbarTabs';
import Aboutustabs from './Aboutustabs';
export default function BlueNavbar() {
  const language = useSelector((state) => state.globalLanguage);
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const [isSelected, setIsSelected] = useState(false);
  const [showNavbarTabs, setShowNavbarTabs] = useState(false);
  const [showAboutusTabs, setShowAboutusTabs] = useState(false);
  const navigate = useNavigate();

  const handleNavClick = (path) => {
    navigate(path);
  };

  const handleBrowseClick = () => {
    setShowNavbarTabs((prev) => !prev); 
    setShowAboutusTabs(false); 
  };

  const handleAboutUsClick = () => {
    setShowAboutusTabs((prev) => !prev);
    setShowNavbarTabs(false);
  };
  return (
    <div className="bg-[lightblue]">
      <div
        className="flex gap-2 items-center flex-wrap servicebox-padding  px-[50px] py-[10px]"
        style={{ fontSize: `${paraFont}px` }}
      >
        <div className="nav-window-items">
          <span
            className="cursor-pointer font-semibold"
            onClick={() => handleNavClick("/")}
          >
            {language ? "Home" : "मुख्य पृष्ठ"}
          </span>
        </div>
        |
        <div className={`browse-container`}>
          <span
            onClick={handleBrowseClick}
            className="browse cursor-pointer font-semibold"
            style={{ fontSize: `${paraFont}px` }}
          >
            {language ? "Browse" : "खोज"}{" "}
            {showNavbarTabs ? (
              <i className="text-sm fa-solid fa-chevron-up"></i>
            ) : (
              <i className="text-sm fa-solid fa-chevron-down"></i>
            )}
          </span>
        </div>
        |
        {/* <span
          className="cursor-pointer font-semibold"
          style={{ fontSize: `${paraFont}px` }}
        >
          {language ? "Services" : "सेवाएं"}
        </span>
        | */}
        <div className={`browse-container`}>
          <span
            onClick={handleAboutUsClick}
            className="browse cursor-pointer font-semibold"
            style={{ fontSize: `${paraFont}px` }}
          >
            {language ? "About Us" : "हमारे बारे में"}{" "}
            {showAboutusTabs ? (
              <i className="text-sm fa-solid fa-chevron-up"></i>
            ) : (
              <i className="text-sm fa-solid fa-chevron-down"></i>
            )}
          </span>
        </div>
      </div>
      {showNavbarTabs && (
        <div className="NavbarTabs shownavbar">
          <NavbarTabs />
        </div>
      )}
      {showAboutusTabs && (
        <div className="AboutUsTabs shownavbar">
          <Aboutustabs />
        </div>
      )}
    </div>
  );
  
}
