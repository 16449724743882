import React from 'react'
import bannerimg from "../../Assests/library_2.jpg"
import "./LibraryMainPage.css";
import { showLang } from "../../Redux/Slices/globalLanguage";
import { useDispatch, useSelector } from "react-redux";
export default function Banner() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const dispatch = useDispatch();
  const handleLanguage = (state) => {
    console.log(state)
    dispatch(showLang(state));
  };
  return (
    <div  className="relative">
      <img src={bannerimg} alt="IIT Roorkee Logo" style={{ width: '100%' }} className='imagebanner'/>
      <div className="absolute inset-0 flex items-center bg-black bg-opacity-50">
        {/* <div className="text-center bg-white bg-opacity-60  overlay-border max-w-[90%] w-fit flex flex-col" 
            style={{ padding: '20px 20px'}}>

            <h1 className="text-yellow-400 font-semibold Mahatma-text" style={{ fontSize: `${headingFont}px`}}>
              {language ? "Mahatma Gandhi Central Library" : "महात्मा गांधी केंद्रीय पुस्तकालय"} 
            </h1>

            <hr style={{ border: 'none', height: '2px', backgroundColor: 'black', margin: '10px 0px' }} />

            <h2 className=" text-gray-800 mt-2 font-normal iit-text" style={{ fontSize: `${headingFont}px`}}>
            {language ? "Indian Institute of Technology," : "भारतीय प्रौद्योगिकी संस्थान"} <span className="font-semibold">{language ? "Roorkee" : "रुड़की"}</span>
            </h2>

            <button 
              className="mt-4 mr-5 self-end bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-full hover:bg-gray-400"
              style={{ fontSize: `${navbarFont}px`}}
            >
            {language ? "Latest Updates" : " नई सुचनाऐ"}   
            </button>
        </div> */}
  </div>

    </div>
  )
}
