import { createSlice } from "@reduxjs/toolkit";

const initialState = true;

const globalLanguage = createSlice({
  name: "globalLanguage",
  initialState,
  reducers: {
    showLang(state, action) {
      return action.payload;
    },
  },
});
export const { showLang } = globalLanguage.actions; 
export default globalLanguage.reducer;
