import React from 'react'
import Navbar from './Navbar'
import Banner from './Banner'
import ServicesInBoxes from './ServicesInBox/ServicesInBoxes'
import NewsAndAnnouncement from './NewsAndAnnouncement'
import PictureBanner from './PictureBanner'
import ImportantLinks from './ImpLinks/ImportantLinks'
import PhotoGallery from './PhotoGallery'
import WebFooter from './WebFooter'
import BlueNavbar from './BlueNavbar'
export default function LibraryMainPage() {
  return (
    <div className='overflow-y-scroll overflow-x-hidden h-screen'>
      <Navbar/>
      <div className='NavbarTabs'><BlueNavbar/></div>
      <Banner/>
      <ServicesInBoxes/>
      <NewsAndAnnouncement/>
      {/* <PictureBanner/> */}
      <ImportantLinks/>
      <PhotoGallery/>
      <WebFooter/>
    </div>
  )
}
