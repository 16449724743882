import { createSlice } from '@reduxjs/toolkit';

const initialState = 36; // Default font size (in pixels)

const headingFontSlice = createSlice({
  name: 'headingFontSlice',
  initialState,
  reducers: {
    increaseHeadingFontSize(state) {
      return state + 2; // Increase font size by 2px
    },
    decreaseHeadingFontSize(state) {
      return state - 2; // Decrease font size by 2px
    },
    setHeadingFontSize(state, action) {
        // Ensure payload is a valid number before updating the state
        if (typeof action.payload === 'number' && action.payload > 0) {
          return action.payload; // Set to a specific font size
        }
        return state; // Return current state if payload is invalid
      },
  },
});

export const { increaseHeadingFontSize, decreaseHeadingFontSize, setHeadingFontSize } = headingFontSlice.actions;
export default headingFontSlice.reducer;
