import React, { useState } from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import store from "../src/Redux/Store";
import LibraryMainPage from "./Pages/Home/LibraryMainPage";
import History from "./Pages/NavbarTabs/AboutUs/History";
import OurTeam from "./Pages/NavbarTabs/AboutUs/OurTeam";
import Collections from "./Pages/NavbarTabs/AboutUs/Collections";
import HoursandHolidays from "./Pages/NavbarTabs/AboutUs/HoursandHolidays";
import ContactUsPage from "./Pages/Home/ContactUsPage";
import FAQ from "./Pages/Home/ImpLinks/FAQ";
import EventsAtMGCL from "./Pages/NavbarTabs/AboutUs/EventsAtMGCL";
import RequestForBooksAndResearchPpr from "./Pages/Home/ServicesInBox/RequestForBooksAndResearchPpr";
import NewViewAllPage from "./Pages/Home/NewViewAllPage";
function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App bg-white">
          <ToastContainer />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <div>
                  <LibraryMainPage />
                </div>
              }
            />
            <Route
              exact
              path="/about-us-history"
              element={
                <div>
                  <History />
                </div>
              }
            />
            <Route
              exact
              path="/about-us-ourTeam"
              element={
                <div>
                  <OurTeam />
                </div>
              }
            />
            <Route
              exact
              path="/about-us-collections"
              element={
                <div>
                  <Collections />
                </div>
              }
            />
            <Route
              exact
              path="/about-us-holidays"
              element={
                <div>
                  <HoursandHolidays />
                </div>
              }
            />
            <Route
              exact
              path="/contact-us"
              element={
                <div>
                  <ContactUsPage />
                </div>
              }
            />
            <Route
              exact
              path="/faqs"
              element={
                <div>
                  <FAQ />
                </div>
              }
            />
            <Route
              exact
              path="/requests"
              element={
                <div>
                  <RequestForBooksAndResearchPpr />
                </div>
              }
            />
             <Route
              exact
              path="/events"
              element={
                <div>
                  <EventsAtMGCL/>
                </div>
              }
            />
            <Route
              exact
              path="/news-and-announcements"
              element={
                <div>
                  <NewViewAllPage/>
                </div>
              }
            />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
