import React from 'react'
import Navbar from './Navbar'
import BlueNavbar from './BlueNavbar';
import WebFooter from './WebFooter'
import { useSelector } from 'react-redux';
export default function ContactUsPage() {
    const paraFont = useSelector((state) => state.fontSizeSlice);
    const language = useSelector((state) => state.globalLanguage);
    const styles = {
        cardContainer: {
          border: '1px solid #ccc',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        },
      };
  return (
    <div>
      <Navbar/>
      <div className='NavbarTabs'><BlueNavbar/></div>
      <div className='h-[80vh] flex justify-center items-center responsive-contactus-box'>
                <div style={styles.cardContainer  } className='bg-[#e7f3f8] h-fit p-5 rounded-2xl w-fit text-center '>
                <h3 className='mb-[10px]' style={{ fontSize: `${paraFont}px`}}>{language ? "Mahatama Gandhi Central Library": "महात्मा गांधी केंद्रीय पुस्तकालय" }</h3>
                <p className='mb-[5px]' style={{ fontSize: `${paraFont}px`}}>{language ? "Indian Institute of Technology, Roorkee": "भारतीय प्रौद्योगिकी संस्थान, रुड़की" }</p>
                <p className='mb-[10px]' style={{ fontSize: `${paraFont}px`}}>{language ? "Roorkee-247667": "रुड़की-247667" }</p>
                <p className='mb-[5px]'style={{ fontSize: `${paraFont}px`}}>
                    <strong>{language ? "Telephone:": "टेलीफ़ोन:" }</strong> + 91-1332-285239
                </p>
                <p className='mb-[5px]' style={{ fontSize: `${paraFont}px`}}>
                    <strong>{language ? "Fax:": "फैक्स:" }</strong> + 91-1332-273560
                </p>
                <p className='mb-[5px]' style={{ fontSize: `${paraFont}px`}}>
                    <strong>{language ? "Email:": "ईमेल:" }</strong> <a href="mailto:library@iitr.ac.in">library@iitr.ac.in</a>
                </p>
                <p className='mb-[5px]' style={{ fontSize: `${paraFont}px`}}>
                    <strong>{language ? "Web:": "वेब:" }</strong> <a href="http://www.mgcl.iitr.ac.in" target="_blank" rel="noopener noreferrer">www.mgcl.iitr.ac.in</a>
                </p>
                </div>
      </div>
      <WebFooter/>
    </div>
  )
}
