import { createSlice } from '@reduxjs/toolkit';

const initialState = 16; // Default font size (in pixels)

const fontSizeSlice = createSlice({
  name: 'fontSize',
  initialState,
  reducers: {
    increaseParaFontSize(state) {
      return state + 2; // Increase font size by 2px
    },
    decreaseParaFontSize(state) {
      return state - 2; // Decrease font size by 2px
    },
    setParaFontSize(state, action) {
      // Ensure payload is a valid number before updating the state
      if (typeof action.payload === 'number' && action.payload > 0) {
        return action.payload; // Set to a specific font size
      }
      return state; // Return current state if payload is invalid
    },
  },
});

export const { increaseParaFontSize, decreaseParaFontSize, setParaFontSize } = fontSizeSlice.actions;
export default fontSizeSlice.reducer;
