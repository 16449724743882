import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
export default function Aboutustabs() {
    const navbarFont = useSelector((state) => state.navbarFontSlice);
    const paraFont = useSelector((state) => state.fontSizeSlice);
    const headingFont = useSelector((state) => state.headingFontSlice);
    const language = useSelector((state) => state.globalLanguage);
    const navigate = useNavigate();
    const handleNavClick = (path) => {
        navigate(path);
      };
  return (
    <div className="nav-window-items">
    <div
      className="vision-cont servicebox-padding relative  w-full flex-wrap grid grid-cols-3 bg-white px-[26px] shadow-md"
      style={{ fontSize: `${paraFont}px` }}
    >
      <a
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
        onClick={() => handleNavClick("/about-us-history")}
      >
        {language ? "History" : "इतिहास"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
      <a
        href="https://mgcl.iitr.ac.in/Docs/fp.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
      >
        {language ? "Floor Plan" : "फ्लोर प्लान"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
      <a
        href="https://mgcl.iitr.ac.in/guide.html"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
      >
        {language ? "User's Guide" : "उपयोगकर्ता मार्गदर्शिका"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
      <a
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
        onClick={() => handleNavClick("/about-us-ourTeam")}
      >
        {language ? "Our Team" : "हमारी टीम"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
      <a
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
        onClick={() => handleNavClick("/about-us-collections")}
      >
        {language ? "Collections" : "संग्रह"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
      <a
        href="https://mgcl.iitr.ac.in/services/1.html"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
      >
        {language ? "Services" : "सेवाएँ"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
      <a
        href="https://doaj.org/"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
      >
        {language ? "Vision & Mission" : "दृष्टि और मिशन"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
      <a
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
        onClick={() => handleNavClick("/about-us-holidays")}
      >
        {language ? "Library Hours & Holidays" : "लाइब्रेरी का समय और छुट्टियाँ"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
      <a
        href="https://mgcl.iitr.ac.in/Docs/qp.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
      >
        {language ? "Quality Policy & Objectives" : "गुणवत्ता नीति और उद्देश्य"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
      <a
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
        onClick={() => handleNavClick("/events")}
      >
        {language ? "Events at MGCL" : "MGCL में कार्यक्रम"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
      <a
        href="https://mgcl.iitr.ac.in/Docs/mgcl%20at%20a%20glane.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer py-2 px-4 font-semibold hover:bg-[#e7f3f8] flex justify-between items-center"
      >
        {language ? "MGCL at a Glance" : "MGCL एक नज़र में"}
        <i className="fa-solid fa-arrow-up-right-from-square ml-4"></i>
      </a>
    </div>
  </div>
  )
}
