import React from 'react'
import { useNavigate } from 'react-router-dom';
import { showLang } from "../../Redux/Slices/globalLanguage";
import { useDispatch, useSelector } from "react-redux";
import whitelogo from "../../Assests/white-logo.png"
export default function WebFooter() {
  const navigate = useNavigate();
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const dispatch = useDispatch();
  const handleLanguage = (state) => {
    console.log(state)
    dispatch(showLang(state));
  };
  const handleNavClick = (path) => {
    navigate(path);
  };
  return (
    <div>
    <div className="  bg-[#003B4F] text-white servicebox-padding" style={{ padding: '20px 50px '}}>
      <div className='flex justify-between items-center flex-wrap footer-gridd'>
        
          <div className='w-1/4 px-[10px] footer-grid-margin'><img style={{ maxWidth:"200px" }} src={whitelogo}/></div>
          <div className='w-1/4 px-[10px] text-left footer-grid-margin2'>
          <ul>
            <li
              onClick={() => handleNavClick("/")}  
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
               {language ? "Home" : "मुख्य पृष्ठ"}</li>
            <li
              onClick={() => handleNavClick("/about-us-ourTeam")}  
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
             {language ? "Our Team": "हमारी टीम" }</li>
            <li
              onClick={() => handleNavClick("/about-us-collections")}
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
              {language ? " Collections": "संग्रह" }</li>
            <li
               onClick={() => handleNavClick("/about-us-history")}
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
               {language ? " History": "इतिहास" }</li>
          </ul>
        </div>
        
      <div className='w-1/4 px-[10px] mapclass2'>
        <a>{language ? " Roorkee - Haridwar Highway, Roorkee, Uttarakhand 247667" : "रूड़की-हरिद्वार हाईवे, रूड़की, उत्तराखंड 247667"}</a>
      </div>
      <div className='w-1/4 px-[10px] mapclass'>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1761791.471654884!2d75.86845882833667!3d30.40777115673486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390eb3650bfe37a7%3A0xa9d19b15af050467!2sIndian%20Institute%20Of%20Technology%20Roorkee!5e0!3m2!1sen!2sin!4v1730183862466!5m2!1sen!2sin"
        width="100%"
        height=""
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="map"
        >
          
        </iframe>
      </div>
        {/* <div className='w-1/4 text-left footer-grid-margin footer-grid-margin3'>
          <ul>
            <li
              href=""  
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
              {language ? "IITR Computer Centre" : "IITR कंप्यूटर सेंटर"}</li>
            <li
              href="https://www.library.iitb.ac.in/"  
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
              {language ? " IIT Bombay" : "IIT बंबई"}</li>
            <li
              href="https://library.iitd.ac.in/"  
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
              {language ? " IIT Delhi" : "IIT दिल्ली"}</li>
            <li
              href="https://www.iitg.ac.in/lib/"  
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
              {language ? " IIT Guwahati" : "IIT गुवाहाटी"}</li>
          </ul>
        </div>
        <div className='w-1/4 text-left footer-grid-margin2 footer-grid-margin3'>
          <ul>
            <li
              href="http://www.library.iitkgp.ernet.in/"  
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
              {language ? " IIT Kharagpur" : "IIT खड़गपुर"}</li>
            <li
              href="http://www.cenlib.iitm.ac.in/docs/library/index.php"  
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
              {language ? " IIT Madras" : "IIT मद्रास"}</li>
            <li
              href="http://www.library.iisc.ernet.in/"  
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
              {language ? " IISc Bangalore" : "IIT बैंगलोर"}</li>
            <li
              href="http://library.iitk.ac.in/Pages/login.htm?0.5130350687222398"  
              style={{ fontSize: `${paraFont}px` }}  
              className="mb-2 weblist cursor-pointer"
            >
              {language ? " IIT Kanpur" : "IIT कानपुर"}</li>
          </ul>
        </div> */}
      
      </div>
    </div>
    <div
      href=""  className=" bg-white text-center my-2">
    <p style={{ fontSize: `${paraFont}px` }}  className=' footer-text'>{language ? "© Indian Institute of Technology," : "© भारतीय प्रौद्योगिकी संस्थान"}<span className="font-semibold">{language ? "Roorkee." : " रुड़की"}</span>{language ? " All Rights Reserved. " : " सर्वाधिकार सुरक्षित"} </p>
  </div>
  </div>
  )
}
