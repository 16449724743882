import { createSlice } from "@reduxjs/toolkit";

const initialState = 14; // Default font size (in pixels)

const navbarFontSlice = createSlice({
  name: "navbarFontSlice",
  initialState,
  reducers: {
    increaseNavFontSize(state) {
      return state + 2; // Increase font size by 2px
    },
    decreaseNavFontSize(state) {
      return state - 2; // Decrease font size by 2px
    },
    setNavFontSize(state, action) {
      // Ensure payload is a valid number before updating the state
      if (typeof action.payload === "number" && action.payload > 0) {
        return action.payload; // Set to a specific font size
      }
      return state; // Return current state if payload is invalid
    },
  },
});

export const { increaseNavFontSize, decreaseNavFontSize, setNavFontSize } =
  navbarFontSlice.actions;
export default navbarFontSlice.reducer;
