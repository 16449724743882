import React from "react";
import { useNavigate } from 'react-router-dom';
import "./LibraryMainPage.css";
import logo from "../../Assests/logoaa.png";
import liblogo from "../../Assests/library logo1.png";
import MobieNav from "./MobieNav";
import ContactUsPage from "./ContactUsPage";
import { useDispatch, useSelector } from "react-redux";
import { showLang } from "../../Redux/Slices/globalLanguage";
import {decreaseHeadingFontSize, setHeadingFontSize, increaseHeadingFontSize} from "../../Redux/Slices/headingFontSlice"
import {decreaseParaFontSize, setParaFontSize, increaseParaFontSize} from "../../Redux/Slices/fontSizeSlice"
import {decreaseNavFontSize, setNavFontSize, increaseNavFontSize} from "../../Redux/Slices/navbarFontSlice"
export default function Navbar() {
  const navigate = useNavigate();
  const language = useSelector((state) => state.globalLanguage);
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const dispatch = useDispatch();
  const handleLanguage = (state) => {
    dispatch(showLang(state));
  };
  const handleSmallFont = (state) => {
    dispatch(decreaseHeadingFontSize(state));
    dispatch(decreaseParaFontSize(state));
    dispatch(decreaseNavFontSize(state));
  };
  const handleNormalFont = () => {
    dispatch(setHeadingFontSize(36));
    dispatch(setParaFontSize(16));
    dispatch(setNavFontSize(14));
  };
  const handleLargeFont = (state) => {
    dispatch(increaseHeadingFontSize(state));
    dispatch(increaseParaFontSize(state));
    dispatch(increaseNavFontSize(state));
  };
  const handleNavClick = (path) => {
    navigate(path);
  };
  return (
    <div className="bg-navblue">
      <div
        className="flex justify-between items-center flex-wrap servicebox-padding"
        style={{ padding: "10px 50px" , fontSize: `${paraFont}px`}}
      >
        <div className="text-black flex gap-1 items-center timingnavbar">
              <span style={{ fontSize: `${paraFont}px` }}>{language ? "Timings:" : "समय-"}</span>
              <span style={{ fontSize: `${paraFont}px` }}>08:30 am - 12:00 am</span>
            </div>
            <div className="mobiledisplaynav">
              <MobieNav />
            </div>
            <div className="flex gap-4 items-center responsive-trio">
          <a 
            className="cursor-pointer"
            onClick={() => handleNavClick("/contact-us")}
            >
             {language ? "Contact Us" : "संपर्क करें"}
             </a>
             {/* <a className="counter-order">
              Counter
             </a> */}
            <div className="flex items-center gap-1 ">
                <span className="font-xsmol text-black cursor-pointer" onClick={handleSmallFont}>
                  -A
                </span>
                <span
                  className="font-lrg font-bold text-black cursor-pointer"
                  onClick={handleNormalFont}
                >
                  A
                </span>
                <span
                  className="text-[22px] font-bold text-black cursor-pointer"
                  onClick={handleLargeFont}
                >
                  A+
                </span>
              </div>
            <div className="flex text-black"  style={{ fontSize: `${paraFont}px` }}>
              <span
                className="cursor-pointer"
                onClick={() => {
                  handleLanguage(false);
                }}
              >
                हिंदी
              </span>
              <span className="mx-1">|</span>
              <span
                className="cursor-pointer"
                onClick={() => {
                  handleLanguage(true);
                }}
              >
                Eng
              </span>
            </div>
            
            </div>
            
      </div>

      <div
        className="bg-primarybg flex justify-between logo-box servicebox-padding"
        style={{ padding: "5px 70px" }}
      >
        <img
          src={logo}
          alt="IIT Roorkee Logo"
          style={{ height: "45px" }}
          className="picture-height cursor-pointer"
          onClick={() => handleNavClick("/")}
        />
        <img
          src={liblogo}
          alt="IIT Roorkee Logo"
          style={{ height: "45px" }}
          className="picture-height cursor-pointer"
          onClick={() => handleNavClick("/")}
        />
      </div>
    </div>
  );
}
