import { configureStore } from '@reduxjs/toolkit';
import fontSizeSlice from './Slices/fontSizeSlice';
import  globalLanguage  from './Slices/globalLanguage'
import navbarFontSlice from "./Slices/navbarFontSlice"
import headingFontSlice from "./Slices/headingFontSlice"

const store = configureStore({
  reducer: {
    fontSizeSlice: fontSizeSlice,
    navbarFontSlice: navbarFontSlice,
    headingFontSlice: headingFontSlice,
    globalLanguage:globalLanguage
  },
});

export default store;
