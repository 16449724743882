import React, { useState } from 'react';
import NavbarTabs from '../NavbarTabs/NavbarTabs'
import Navbar from './Navbar';
import WebFooter from './WebFooter';
import { useSelector } from 'react-redux';
import arrow from "../../Assests/right-arrow.png"
import { useLocation } from 'react-router-dom';
import BlueNavbar from './BlueNavbar';
export default function NewViewAllPage() {
  const navbarFont = useSelector((state) => state.navbarFontSlice);
  const paraFont = useSelector((state) => state.fontSizeSlice);
  const headingFont = useSelector((state) => state.headingFontSlice);
  const language = useSelector((state) => state.globalLanguage);
  const location = useLocation();
  const news = location.state?.news || [];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(news.length / itemsPerPage);

  const paginatedNews = news
    .slice()
    .reverse()
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Navbar />
      <div className="NavbarTabs">
        <BlueNavbar />
      </div>

      <div
        className="bg-white p-6 rounded-lg shadow-lg responsive-padding-for-all"
        style={{ padding: "20px 50px" }}
      >
        <h2
          className="font-semibold text-black mb-[20px] mx-auto w-fit"
          style={{ fontSize: `${headingFont}px` }}
        >
          {language ? "News & Announcements" : "समाचार एवं घोषणाएँ"}
        </h2>

        <div
          className="space-y-5 bg-primarybg p-5 new-window"
          style={{ borderRadius: "24px" }}
        >
          {paginatedNews.length > 0 ? (
            paginatedNews.map((item) => (
              <div
                key={item?._id}
                className="flex justify-between items-center bg-white news-windows p-4 shadow-sm"
                style={{ borderRadius: "16px" }}
              >
                <div>
                  <p
                    className="text-left font-bold"
                    style={{ fontSize: `${paraFont}px` }}
                  >
                    {`${item?.time} | ${item?.date} | ${item?.department}`}
                  </p>
                  <h3
                    className="font-bold text-left mt-[10px]"
                    style={{ fontSize: `${paraFont}px` }}
                  >
                    {language ? item?.title : item?.title2}
                  </h3>
                </div>
                <div className="bg-navblue p-3">
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
            ))
          ) : (
            <p>No news available</p>
          )}
        </div>

        {/* Pagination Controls */}
        <div className="button-group-events mt-[20px] flex justify-center space-x-2">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              style={{ fontSize: `${headingFont}px` }}
              className={`button-eventsMGCL ${
                currentPage === index + 1 ? "selected" : ""
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      <WebFooter />
    </div>
  );
}
