import React, { useEffect, useState } from "react";
import "./AboutUs.css"
import BlueNavbar from "../../Home/BlueNavbar";
import Navbar from "../../Home/Navbar"
import WebFooter from "../../Home/WebFooter"
import { useSelector } from 'react-redux';
import axios from "axios";
import DOMPurify from 'dompurify';
import libimg from "../../../Assests/iitrlib-img.jpeg"
export default function Collections() {
    const navbarFont = useSelector((state) => state.navbarFontSlice);
    const paraFont = useSelector((state) => state.fontSizeSlice);
    const headingFont = useSelector((state) => state.headingFontSlice);
    const language = useSelector((state) => state.globalLanguage);
    const [faq, setFaq] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sections, setSections] = useState({
      collectionandresource: null,
    });
    const [openIndex, setOpenIndex] = useState(null); 
    const fetchFaq = async () => {
        const token = localStorage.getItem("accessToken");
        if (!token) {
          console.error("Access token is missing");
          return;
        }
    
        try {
          const { data } = await axios.get(`http://localhost:8000/admin/getAllFaq`, {
            headers: {
              Authorization: token,
            },
          });
          setFaq(data.data);
          console.log(data.data);
        } catch (error) {
          console.error("Error fetching faqs:", error);
        }
      };
    const fetchContent = async () => {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      try {
        const responses = await Promise.all([
          axios.get('http://localhost:8000/admin/getAboutUs/collectionandresource', {
            headers: { Authorization: token }
          }),
        ]);
        setSections({
          collectionandresource: {
            content: responses[0].data.data.html,
            image: libimg,
          },
        });
      } catch (error) {
        console.error("Error fetching content:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    const toggleAccordion = (index) => {
      if (openIndex === index) {
        setOpenIndex(null);
      } else {
        setOpenIndex(index);
      }
    };
  
    useEffect(() => {
      fetchContent();
      fetchFaq();
    }, []);
  
    const renderSection = (title, section) => {
      if (!section?.content) return null;
      return (
        <div className="bg-white p-[20px] rounded-3xl inner-responsive-padding">
          {section.image && (
            <img 
              src={section.image}
              alt={title.english}
              className="w-full mb-[30px] rounded-2xl"
              style={{ aspectRatio: '1/0.4' }}
              onError={(e) => {
                if (title.english === "Collections and Resources") e.target.src = libimg;
              }}
            />
          )}
          <div className="mt-7">
            <div 
              className="bg-primarybg rounded-2xl text-padding-inner p-4 text-justify font-semibold"
              style={{ fontSize: `${paraFont}px` }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.content) }}
            />
          </div>
        </div>
      );
    };
  
    return (
      <div>
        <Navbar />
        <div className='NavbarTabs'><BlueNavbar/></div>
        <div>
          <div className="bg-primarybg py-3">
            <div className="p-[10px]">
              <span className="font-semibold text-black mb-[20px] mx-auto w-fit" style={{ fontSize: `${headingFont}px` }}>
                {language ? "Collections and Resources" : "संग्रह और संसाधन"}
              </span>
            </div>
          </div>
          <div className="bg-primarybg responsive-padding-for-all" style={{ padding: '0px 50px 20px' }}>
            {renderSection(
              { english: "Collections and Resources", hindi: "संग्रह और संसाधन" },
              sections.collectionandresource
            )}
            <div className="pt-14">
              <p className="font-semibold text-black mx-auto w-fit" style={{ fontSize: `${headingFont}px` }}>
                {language ? "FAQs" : "पूछे जाने वाले प्रश्न"}
              </p>
              <p className="font-thin text-gray-500 mx-auto w-fit" style={{ fontSize: `${paraFont}px` }}>
                {language ? "Your doubt not listed? Drop mail at " : "आपका संदेह सूचीबद्ध नहीं है? यहाँ मेल छोड़ें"}
                <a href="https://mgcl.iitr.ac.in/" className="text-black font-semibold ml-1">
                  mgcl.iitr.ac.in
                </a>
              </p>
            </div>
          </div>    
          <div className="bg-white responsive-padding-for-all" style={{ padding: '20px 50px' }}>
            <div className="bg-primarybg p-5 rounded-3xl flex justify-center faq-padding-inner">
              <div className="w-[80%] faq-width">
                {faq.length > 0 ? (
                faq.map((item, index) => (
                  <div key={item?._id}>
                    <button
                      onClick={() => toggleAccordion(index)}
                      className={`w-full flex items-center justify-between p-3 focus:outline-none transition-all 
                      ${openIndex === index ? "" : "border-b border-black"}`}
                    >
                      <span className="mr-4" style={{ fontSize: `${paraFont}px` }}>
                        {openIndex === index ? '-' : '+'}
                      </span>
                      <span className="text-left font-semibold flex-grow" style={{ fontSize: `${paraFont}px` }}>
                        {language ? item?.question : item?.question2}
                      </span>
                    </button>
                    {openIndex === index && (
                      <div style={{ fontSize: `${paraFont}px` }} className="px-3 pb-3 text-left border-b flex border-black">
                        <div className="h-full"><span className="mr-4 font-semibold" style={{ color:'#F4F5FB', fontSize: `${paraFont}px` }}>
                          {openIndex === index ? '-' : '+'}
                        </span></div>
                        {language ? item?.answer : item?.answer2}
                      </div>
                    )}
                  </div>
                  ))
                  ) : (
                    <p>Loading faq...</p>
                  )}
              </div>
            </div>
          </div>
        </div>
        <WebFooter />
      </div>
    );
  }